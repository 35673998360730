@import '../variables';
.pagination-container {
    .show-of {
        color: $gray-128;
    }
    .v-separator {
        height: 30px;
        border-left: 1px solid $gray-192;
    }
    .pagination-select {
        p {
            color: $gray-128;
        }
        .basic-select {
            .select__control {
                background-color: transparent !important;
                border: none;
                box-shadow: none;
                border-bottom: 2px solid black;
                border-radius: 0px;
                width: 66px;
                .select__single-value {
                    font-size: 14px;
                    font-weight: bold;
                }
                .select__indicators {
                    .select__dropdown-indicator {
                        color: black;
                        padding: 6px;
                    }
                    .select__indicator-separator {
                        display: none;
                    }
                }
                .select__value-container {
                    padding: 0px;
                }
            }
            .select__menu {
                color: $gray-128;
                margin: 0px;
                margin-top: -2px;
                border: 2px solid black;
                border-radius: 0px;
                outline: none;
                box-shadow: none;
                width: 66px;
                .select__menu-list {
                    padding-bottom: 0px;
                    padding-top: 0px;
                    .select__option:not(:last-child) {
                        border-bottom: 1px solid black;
                    }
                    .select__option--is-focused {
                        background: none;
                        color: black;
                    }
                    .select__option--is-selected {
                        background: none;
                        color: black;
                    }
                }
            }
        }
    }
    .pagination {
        margin-top: 0px;
        flex: 1;
        margin-bottom: 0px;
        .next,
        .previous {
            a {
                font-size: 14px;
                color: black;
                font-weight: bold;
                background: none !important;
            }
        }
        .selected {
            a {
                font-weight: bold;
                background-color: $gray-224 !important;
            }
        }
        .active {
            a {
                z-index: 1;
                color: white;
                background-color: $blue-195;
                border-color: $loginpage;
                font-weight: bold;
            }
        }
    }
    li {
        padding-right: 7px;
        a {
            //font-weight: 900;
            font-size: 12px;
            outline: 0;
            position: relative;
            display: block;
            padding: 0.3rem 0.55rem;
            margin-left: -1px;
            line-height: 1.25;
            color: black;
            background-color: $gray-240;

            i {
                color: $gray-128;
            }
            &:hover {
                z-index: 2;
                font-weight: bold;
                text-decoration: none;
                //background-color: $gray-128;
                cursor: pointer;
            }
        }
    }
    .fa {
        color: black;
        font-size: 16px;
    }
}
