@import '../scss/variables';
// Page locking spinner
.general-spinner {
    padding: 100%;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(white, 0.7);
    .spinner {
        background-color: $gray-208;
    }
}

// Global spinner
.spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: $gray-128;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
}

.spinner.inline {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 37px;
}

.spinner.centered {
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

// Secondary spinner
.dot-spinner > div {
    width: 2px;
    height: 2px;
    margin-right: 3px;
    background-color: $gray-80;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.dot-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.dot-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.spinner-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.circular-spinner {
    border: 16px solid $gray-208;
    border-radius: 50%;
    border-top: 16px solid $gray-128;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.circular-spinner.inline {
    border-width: 2px;
    border-top-width: 2px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: 5px;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
