@import '../variables';
@mixin button-padding {
    padding: 8px;
}
.button-container {
    button {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .button-white {
        &:hover {
            opacity: 0.8;
        }
        background-color: white !important;
        color: black !important;
        border: none;
        border-radius: 0px;
    }
    .button-light {
        &:hover {
            background-color: lighten($blue-190-2, 10%) !important;
        }
        background-color: $blue-190-2 !important;
        color: white !important;
        border: none;
        border-radius: 0px;
    }
    .button-transparent {
        &:hover {
            background-color: $gray-240 !important;
        }
        background-color: transparent !important;
        color: black !important;
        border-radius: 0px;
        border: 1.5px solid black;
    }

    .button-dark {
        &:hover {
            background-color: $gray-208 !important;
        }
        background-color: $gray-240 !important;
        color: black !important;
        border: none;
        border-radius: 0px;
    }

    .button-xs {
        @include button-padding();
        min-width: 85px;
        height: auto;
        font-size: 10px;
        outline: none !important;
        cursor: pointer;
    }

    .button-2xs {
        @include button-padding();
        min-width: 75px;
        height: 30px;
        font-size: 10px;
        outline: none !important;
        cursor: pointer;
    }

    .button-sm {
        @include button-padding();
        min-width: 142px;
        height: 40px;
        font-size: 12px;
        font-weight: bold;
        outline: none !important;
        cursor: pointer;
        letter-spacing: 1.5px;
    }

    .button-md {
        padding: 2px 20px 2px 20px;
        min-width: 176px;
        height: 40px;
        font-size: 14px;
        outline: none !important;
        cursor: pointer;
        letter-spacing: 1.5px;
    }

    .button-lg {
        @include button-padding();
        width: 100%;
        min-width: 201px;
        height: 40px;
        font-size: 14px;
        outline: none !important;
        cursor: pointer;
        letter-spacing: 1.5px;
    }

    .button-xlg {
        @include button-padding();
        min-width: 260px;
        height: 40px;
        font-size: 14px;
        outline: none !important;
        cursor: pointer;
        letter-spacing: 1.5px;
    }
}
