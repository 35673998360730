.entry-saver-component {
    margin-top: -34px;
    .input-item {
        input {
            background-color: $gray-240;
            border: none;
        }
        label {
            margin-bottom: 1rem;
        }
    }
}
