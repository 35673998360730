@import '../variables';
.errorBoundary {
    background: white;
    height: 100vh;

    .container {
        padding-top: 35vh;

        .title {
            font-size: 50px !important;
            font-weight: bold;
            letter-spacing: 3px;
            color: $blue-190-2 !important;
        }
        .description {
            color: $gray-128;
            font-size: 14px;
        }
    }
}
