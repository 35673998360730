@import '../../../../scss/variables';

header {
    top: 0;
    height: 80px;
    background: white;
    position: fixed;
    z-index: 999;
    width: 100%;
    font-weight: bold;
    letter-spacing: 0.6px;
    font-size: 12px;
    justify-content: space-between;
    .sidebar-toggler {
        color: black;
        font-size: 25px;
        padding: 17px 30px 15px 50px;
        .icon-menu {
            font-weight: bold;
        }
    }
    .header-logo {
        width: 96px;
        height: 25px;
        &.bizpay-logo {
            width: 184px;
            height: 50px;
        }
    }
    .header-menu {
        .letter-spacing {
            letter-spacing: 1.2px;
        }
        .menu-item {
            cursor: pointer;
            color: $gray-128;
            border-right: 1px solid $gray-128;
            &:last-child {
                border-right: none;
            }
            &:hover {
                color: black;
            }
        }
    }
    .header-dropdown {
        min-width: 300px;
        height: 80px;
        color: white;
        background-color: $blue-190-2 !important;
        cursor: pointer;
        .client-select {
            .user-avatar {
                margin: 0 20px 0 0;
                max-width: 30px;
            }
            svg {
                transition: 0.2s;
            }
        }
    }
    .user-dropdown-menu {
        max-height: 82vh;
        overflow-y: auto;
        background-color: white;
        .search-input {
            padding: 10px 30px 10px 50px;
            input {
                width: 100%;
                padding: 10px 0px;
                border: none;
                border-bottom: 1px solid $gray-208;
                outline: none;
                text-transform: uppercase;
            }
            .search-icon {
                margin-top: -35px;
                color: $gray-128;
                background: white;
                width: 20px;
                height: auto;
            }
        }
        .dropdown-button {
            text-transform: uppercase;
            font-weight: bold;
            color: $gray-128;
            padding: 9px 30px 9px 50px;
            letter-spacing: 0.6px;
            &:hover {
                color: black;
            }
            &:after {
                content: '';
                display: block;
                width: 30px;
                border-top: 1px solid $gray-184;
                margin: 14px 0px 0px 0px;
            }
            &.selected {
                color: black;
            }

            &:last-child {
                &:after {
                    border-top: 0px;
                }
            }
        }
        .category-header {
            .dropdown-button {
                &:last-child {
                    &:after {
                        border-top: 1px solid $gray-184;
                    }
                }
            }
            .category-button {
                display: flex;
                justify-content: space-between;
                padding: 9px 30px 9px 50px;
                box-sizing: border-box;
                p {
                    text-transform: uppercase;
                    font-weight: bold;
                    color: $gray-128;
                    letter-spacing: 0.6px;
                    &:after {
                        content: '';
                        display: block;
                        width: 30px;
                        border-top: 1px solid $gray-184;
                        margin: 14px 0px 0px 0px;
                    }
                }
                svg {
                    transition: 0.2s;
                    color: $gray-128;
                }
                &:hover {
                    svg,
                    p {
                        color: black;
                    }
                }
            }
            .menu-category {
                padding-left: 30px;
            }
        }
    }

    button {
        all: unset;
    }
}
