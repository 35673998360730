@import '../../common';
.itab-head {
    border-bottom: none;
    background-color: transparent;
    .nav-item {
        border-bottom: none;
        margin: 0 3rem 0 0;
        .nav-link {
            border: none;
            padding: 0.2rem 0;
            color: $gray-128;
            &.active {
                border-bottom: 2px solid $blue-207;
                background-color: transparent;
                color: black;
            }
        }
    }
}
.itab-content {
    border: none;
    background: transparent;
    .tab-pane {
        padding: 0;
        position: relative;
        .customOverlay {
            @include customOverlay;
        }
    }
}
