@import '../variables';
.filter-component {
    margin-top: 40px;
    margin-bottom: 80px;
    .filter-item {
        label {
            margin-bottom: 1rem;
        }
        input {
            border: none;
            border-bottom: 2px solid black;
            border-radius: 0px;
            &:read-only {
                background: $gray-240 !important;
                border-bottom: none;
            }
        }
        .form-control {
            background: transparent !important;
            padding: 0.46rem 0rem;
            height: 38px;
        }
        .basic-multi-select {
            &.currency {
                z-index: 10;
            }
            .select__control {
                background: transparent !important;
                border: none;
                box-shadow: none;
                border-bottom: 2px solid black !important;
                border-radius: 0px;
                .select__single-value {
                    color: black !important;
                    font-size: 14px;
                }
                .select__indicators {
                    .select__clear-indicator {
                        color: black;
                    }
                    .select__indicator-separator {
                        display: none;
                    }
                    .select__dropdown-indicator {
                        padding: 0px;
                        color: black;
                    }
                }
                .select__multi-value {
                    border-radius: 0px;
                }
                .select__value-container {
                    padding: 2px 0px;
                    .select__single-value {
                        color: $blue-232;
                    }
                    .select__multi-value__label {
                        color: $blue-232;
                    }
                }
            }
            .select__menu {
                color: $gray-128;
                margin: 0px;
                margin-top: -2px;
                border: 2px solid black;
                border-radius: 0px;
                outline: none;
                box-shadow: none;
                z-index: 3;
                background: $gray-240;

                .select__menu-list {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
                .select__option:not(:last-child) {
                    border-bottom: 1px solid black;
                }
                .select__option--is-focused {
                    background: $gray-240 !important;
                }
                .select__option--is-selected {
                    background: $blue-190-2 !important;
                    color: white !important;
                }
            }
        }
        .DateRangePicker,
        .SingleDatePicker {
            input {
                border-bottom: none;
            }
            border-bottom: 2px solid $gray-192;
            padding: 0 0px 4px 0px;
            .DateRangePickerInput,
            .SingleDatePickerInput {
                margin-left: -8px;
                .DateInput__small {
                    .DateInput_input__small {
                        padding: 9px 0px 5px 11px;
                        font-size: 14px;
                    }
                }
                .DateRangePickerInput_arrow {
                    // display: none;
                }
            }
        }
        .date-label {
            .from {
                margin-right: 128px;
            }
        }
    }

    .single-date-picker {
        ::placeholder {
            padding-left: 10px;
        }
        label {
            margin-bottom: 1rem;
        }
        input {
            border: none;
            background-color: $gray-240 !important;
            height: 40px;
        }
    }
    .filter-buttons {
        display: flex;
        min-width: 376px;
    }
}
