.input-item-transparent {
    .form-control {
        height: 38px;
    }

    input {
        outline: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $gray-192;
        padding-left: 0;
    }

    label {
        margin-bottom: 1rem;
    }
}

.input-item-grey {
    input {
        border-radius: 18px;
        background-color: $gray-240 !important;
        border: none;
        width: 100%;
    }

    label {
        margin-bottom: 1rem;
    }
}

.select-item-transparent {
    label {
        margin-bottom: 1rem;
    }

    .__select {
        &.status {
            z-index: 11;
        }

        &.currency {
            z-index: 10;
        }

        .select__control {
            border: none;
            box-shadow: none;
            border-bottom: 2px solid $gray-192;
            border-radius: 0px;

            .select__single-value {
                font-weight: bold;
                font-size: 14px;
            }

            .select__indicators {
                .select__indicator-separator {
                    display: none;
                }

                .select__dropdown-indicator {
                    padding: 0px;
                }
            }

            .select__multi-value {
                border-radius: 0px;
            }

            .select__value-container {
                padding: 2px 0px;

                .select__single-value {
                    color: $blue-232;
                }

                .select__multi-value__label {
                    color: $blue-232;
                }
            }
        }

        .select__menu {
            color: $gray-128;
            margin: 0px;
            margin-top: -2px;
            border: 2px solid $gray-192;
            border-radius: 0px;
            outline: none;
            box-shadow: none;
            z-index: 3;

            .select__option:not(:last-child) {
                border-bottom: 1px solid $gray-192;
            }

            .select__option--is-focused {
                background: none;
                color: $blue-232;
            }

            .select__option--is-selected {
                background: none;
                color: $blue-232;
            }
        }
    }
}
