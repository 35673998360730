@import '../variables';

.card-container {
    .card-radio {
        height: 20px;
        width: 20px;
        margin-bottom: 18px;
        border: 2px solid $gray-192;
        border-radius: 30px;
    }

    &.active {
        .card-radio {
            display: flex;
            align-items: center;
            border-color: $blue-190-2;
            .card-radio-selected {
                height: 12px;
                width: 12px;
                border-radius: 30px;
                margin: auto;
                background-color: $blue-190-2;
            }
        }
    }

    .card-content {
        padding: 6px 0;
        .title-text {
            color: $gray-128;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
            padding: 2px 0;
        }
        .content-text {
            color: $gray-80;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 17px;
            padding: 2px 0;
            width: 100%;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            margin: 0 auto;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .card-actions {
        display: grid;
        grid-template-columns: repeat(6, 18px);
        grid-gap: 12px;
        direction: rtl;
        .card-action {
            cursor: pointer;
        }
    }
}
