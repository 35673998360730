@import '../variables';

.multi-select {
    .dropdown {
        .dropdown-heading {
            background-color: transparent !important;
            border: none !important;
            border-bottom: 2px solid black !important;
            border-radius: 0px !important;
            height: 38px !important;
            color: $gray-128;
            .dropdown-heading-value {
                line-height: 36px !important;
                padding-left: 0px !important;
                padding-right: 5px !important;
                max-width: 90% !important;
                color: black !important;
            }
            .dropdown-heading-loading-container {
                cursor: auto !important;
            }
            .dropdown-heading-dropdown-arrow {
                cursor: auto !important;
                font: normal normal normal 14px/1 FontAwesome, sans-serif;
                font-size: 1.5em !important;
                font-weight: bold;
                color: black;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                span {
                    display: none !important;
                }
                &:hover {
                    color: $gray-156;
                }
                &:focus {
                    color: $gray-208;
                }
            }
            .dropdown-heading-dropdown-arrow:before {
                content: '\f107';
            }
        }
        .dropdown-content {
            background: $gray-250 !important;
            border: 2px solid black !important;
            border-radius: 0px !important;
            max-height: 297px !important;
            margin-top: -2px !important;
            z-index: 10 !important;
            .select-panel {
                .select-item {
                    margin-bottom: 0px !important;
                    background-color: transparent !important;
                    border-bottom: 1px solid black !important;
                    margin-right: 0px;
                    cursor: auto !important;
                    .item-renderer {
                        input {
                            margin-right: 10px !important;
                        }
                        span {
                            padding: 2px 0px !important;
                        }
                    }
                    &:hover {
                        color: black !important;
                    }
                }
                .select-list {
                    li:last-child {
                        .select-item {
                            border-bottom: none !important;
                        }
                    }
                }
            }
        }
    }
}
