@import '../scss/variables';

html body .app.flex-row.align-items-center {
    height: 100vh;
}

.container-fluid {
    .row {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
    padding-right: 0px;
    padding-left: 0px;
}

a {
    text-decoration: none;
}

p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.form-control {
    font-size: 14px;
}

input,
textarea,
button {
    box-shadow: none !important;
    outline: none !important;
}

.ie-input-fix {
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $gray-240 inset !important;
    }
}
