@import '../scss/variables';
.clientDashboard-page {
    .accounts {
        display: grid;
        grid-template-columns: 1fr repeat(4, 390px) 1fr;
        grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
        grid-gap: 30px;
        padding-top: 30px;
        .account-card {
            background-color: $gray-240;
            opacity: 1;
            padding: 55px 50px 45px;
            color: $gray-128;
            font-size: 14px;
            height: 415px;
            width: 390px;
            display: grid;
            position: relative;
            grid-template-rows: 76px 78px 100px;
            row-gap: 30px;
            .homeCurrencyTag {
                background-color: $blue-190-2;
                position: absolute;
                color: white;
                width: 100%;
                padding: 8.5px 0 8.5px 50px;
                font-size: 10px;
                font-weight: 700;
            }
            .account-card-heading {
                display: grid;
                grid-template-rows: 19px 19px 18px 20px;
                color: black;
                font-size: 14px;
                .title {
                    display: flex;
                    align-items: flex-end;
                    color: $gray-128;
                    font-size: 12px;
                }
                .separator {
                    border-top: 1px solid $gray-184;
                    width: 80%;
                    align-self: baseline;
                    margin-left: 0;
                }
            }
            .balance {
                display: grid;
                grid-template-rows: 14px 37px 17px;
                grid-gap: 5px;

                .heading {
                    font-size: 12px;
                    color: $gray-128;
                }
                .text {
                    font-weight: bold;
                    font-size: 30px;
                    color: $blue-190-2;
                }
                .exact-balance {
                    font-weight: bold;
                    color: black;
                    font-size: 14px;
                }
            }
            .actions {
                display: grid;
                grid-template-rows: 40px;
                row-gap: 20px;
                .anchor-button {
                    background-color: transparent;
                    border: 1.5px solid black;
                    border-radius: 0px;
                    color: black;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: bold;
                    height: 40px;
                    letter-spacing: 1.5px;
                    min-width: 201px;
                    outline: none !important;
                    padding: 7px 0px;
                    width: 100%;
                    text-align: center;
                    &:hover {
                        background-color: $gray-208;
                    }
                }
            }

            /*        .currency {
                font-size: 24px;
                font-weight: bold;
            }

            .blue-text {
                color: $blue-190-2;
            }
            .button-transparent {
                &:hover {
                    background-color: $gray-208 !important;
                }
            }
            .withdrawButton {
                margin-top: 5px;
            }*/
        }
    }
    #toggler {
        background-color: transparent;
        border: none;
        .fa {
            background: $gray-240;
            padding: 11px;
            color: black;
        }
    }
    .v-separator {
        border-left: 1px solid $gray-208;
        height: 100%;
    }
    .link-card {
        .card-title {
            color: black;
            font-weight: bold;
            font-size: 20px;
            letter-spacing: 1.5px;
        }
    }

    .table-container {
        display: inline-block;
    }
}
