@import '../../../../scss/variables';

.footer {
    height: 150px;
    background: $gray-240;
    font-size: 10px;
    color: $gray-128;

    .logo {
        height: 150px;
        background: $blue-190-2;
    }

    .footer-content {
        padding-left: 70px;
        @media (max-width: $tablet) {
            padding: 30px;
        }
    }
}
