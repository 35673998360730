@import '../../scss/variables';

.rfiResponse-submit {
    .submit-block {
        border-bottom: 1px solid $gray-208;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &:last-child {
            border-bottom: none;
        }
    }
    .input-item {
        textarea {
            padding-top: 15px;
            padding-left: 15px;
            background-color: $gray-240;
            border: none;
            height: 100px;
        }

        input {
            padding-top: 15px;
            padding-left: 15px;
            background-color: $gray-240;
            height: 50px;
        }
        .file {
            padding: 5px 0 5px 0;
        }
        label {
            margin-bottom: 1rem;
        }
    }
}
