@import '../variables';
.user-type-selector {
    .item {
        :hover {
            cursor: pointer;
        }
        p {
            font-size: 14px;
            color: $gray-128;
            font-weight: bold;
            padding-top: 3px;
            letter-spacing: 0.7px;
        }
        .option {
            width: 18px;
            height: 18px;
        }
        &.selected {
            p {
                color: black;
            }
            .option {
                color: $blue-190-2;
            }
        }
    }
}
