@import '../scss/variables';

.CompanyProfile-page {
    color: black;

    .userProfileLogo {
        width: 100px;
    }

    .border-r {
        border-right: 1px solid $gray-192;
    }

    .changePassword-form {
        margin-top: 40px;

        .filter-item {
            margin-bottom: 40px;

            label {
                margin-bottom: 1rem;
            }

            input {
                border: none;
                border-bottom: 2px solid $gray-192;
                border-radius: 0px;
                width: 100%;
            }
        }

        .horizontal-right {
            width: 100%;
        }
    }

    .select__menu-list {
        max-height: 235px;
    }

    #toggler {
        background-color: transparent;
        border: none;

        .fa {
            background: $gray-192;
            opacity: 0.7;
            border-radius: 50%;
            padding: 11px;
            color: white;

            &:hover {
                opacity: 1;
            }
        }
    }

    .action-buttons {
        .button-lg {
            margin-bottom: 40px;
        }
    }

    .data-table-v2 {
        .action-button {
            padding: 0px;
            padding-top: 5px;
        }
    }
}
