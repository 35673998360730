@import '../../scss/variables';
.alert-small-question {
    padding: 75px 37px 40px 47px !important;
    min-height: 200px !important;
    .button {
        width: 75px;
        height: 30px;
        outline: 0;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 15px;
        border: none;
    }
    .swal-image {
        position: absolute;
        left: 47px;
        top: 37px;
        margin: 0 !important;
    }
    .swal2-title {
        color: $blue-232 !important;
        font-size: 14px !important;
        font-weight: bold !important;
        text-align: left !important;
    }
    .swal2-actions {
        justify-content: space-between !important;
        margin: 5px 0 0 0 !important;
    }
    .swal2-content {
        flex-grow: 1;
    }
}

.swal2-inputerror-disabled {
    .swal2-inputerror {
        border-color: none !important;
    }
}

.modal-md {
    padding: 80px 140px 100px 140px !important;
    min-height: 200px !important;
    .button {
        padding: 2px 2px 2px 2px;
        min-width: 201px;
        height: 40px;
        font-size: 14px;
        outline: none !important;
        cursor: pointer;
        border: none;
        color: white;
        font-weight: bold;
    }
    .confirm-button {
        &:hover {
            background-color: $blue !important;
        }
        background-color: $blue !important;
        color: white !important;
    }
    .cancel-button {
        &:hover {
            background-color: $gray-240 !important;
        }
        background-color: transparent !important;
        border: 1.5px solid $black-24;
        color: black !important;
    }
    .swal-image {
        position: absolute;
        left: 47px;
        top: 37px;
        margin: 0 !important;
    }
    &.modal-approve {
        .swal2-title {
            color: $green-shade !important;
        }
    }
    &.modal-reject {
        .swal2-title {
            color: $red-13 !important;
        }
    }
    &.modal-request {
        .swal2-title {
            color: black !important;
        }
    }
    &.modal-submit {
        .swal2-title {
            color: black !important;
        }
        .swal2-actions {
            z-index: 0;
            justify-content: flex-end !important;
            margin: 80px 0 0 0 !important;
            .swal2-cancel {
                &:hover {
                    background-color: $gray-240 !important;
                }
                margin-right: 20px;
            }
            .swal2-confirm {
                &:hover {
                    background-color: $blue !important;
                }
            }
        }
    }
    .swal2-title {
        margin: 0 !important;
        font-size: 20px !important;
        font-weight: bold !important;
        text-align: left !important;
        width: 100%;
        letter-spacing: 1.5px;
    }
    .swal2-actions {
        justify-content: space-between !important;
        margin: 5px 0 0 0 !important;
    }
    .swal2-content {
        flex-grow: 1;
        margin-top: 80px !important;
        #swal2-content {
            font-size: 14px !important;
            font-weight: bold !important;
            text-align: left !important;
            width: 100%;
            div {
                font-weight: normal !important;
            }
        }
    }
    .swal2-input {
        font-size: 14px !important;
        border: none !important;
        color: black;
        height: auto !important;
        margin: 0em !important;
        padding: 10px 0 20px 0px !important;
    }
}

.modal-sm {
    padding: 40px 30px 40px 20px !important;
    min-height: 150px !important;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    .button {
        padding: 2px 2px 2px 2px;
        min-width: 201px;
        height: 40px;
        font-size: 14px;
        outline: none !important;
        cursor: pointer;
        border: none;
        color: white;
        font-weight: bold;
    }
    .confirm-button {
        &:hover {
            background-color: $blue !important;
        }
        background-color: $blue !important;
        color: white !important;
    }
    .cancel-button {
        &:hover {
            background-color: $gray-240 !important;
        }
        background-color: transparent !important;
        border: 1.5px solid $black-24;
        color: black !important;
    }
    &.modal-submit {
        .swal2-title {
            color: black !important;
        }
        .swal2-actions {
            z-index: 0;
            justify-content: flex-end !important;
            margin: 40px 0 0 0 !important;
            .swal2-cancel {
                &:hover {
                    background-color: $gray-240 !important;
                }
                margin-right: 20px;
            }
            .swal2-confirm {
                &:hover {
                    background-color: $blue !important;
                }
            }
        }
    }
    .swal2-title {
        margin: 0 !important;
        font-size: 20px !important;
        font-weight: bold !important;
        justify-content: center;
        width: 100%;
        letter-spacing: 1.5px;
    }
}

.swal2-popup {
    border-radius: 0px !important;
    box-shadow: 0px 0px 50px $black-a20;
    background: $gray-250 !important;
}

.modal-multiple {
    ::placeholder {
        color: $gray-128 !important;
    }
    color: black !important;
    padding: 70px 90px 70px 90px !important;
    min-height: 200px !important;
    min-width: 800px !important;
    max-width: 1200px !important;
    .button {
        padding: 4px 2px 2px 2px;
        letter-spacing: 1.5px;
        min-width: 176px;
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        outline: none !important;
        opacity: 1;
        cursor: pointer;
        background: $blue-190-2;
        color: white !important;
    }
    .swal2-cancel {
        border: 2px solid $black-24;
        background: transparent;
        color: black !important;
        &:hover {
            border: 1.5px solid $black-24 !important;
            letter-spacing: 1.5px !important;
            padding: 2px !important;
            background-color: $gray-240 !important;
        }
    }

    &.modal-Approving {
        .swal2-title {
            color: $green-shade !important;
            font-size: 24px !important;
        }
    }
    &.modal-Rejecting {
        .swal2-title {
            color: $red-13 !important;
            font-size: 24px !important;
        }
    }
    &.modal-Requesting {
        .swal2-title {
            color: black !important;
            font-size: 20px !important;
        }
    }
    .swal2-title {
        margin: 0 !important;
        font-size: 24px !important;
        font-weight: bold !important;
        text-align: left !important;
        width: 100%;
        letter-spacing: 1.5px;
    }
    .swal2-actions {
        justify-content: flex-end !important;
        margin: 50px 0 0 0 !important;
        .swal2-cancel {
            margin-right: 30px;
        }
    }
    .swal2-content {
        flex-grow: 1;
        #swal2-content {
            font-size: 14px !important;
            text-align: left !important;
            width: 100%;
            .modal-table {
                width: 100%;
                tr {
                    border-bottom: 2px solid $gray-208;
                    &:last-child {
                        border-bottom: none;
                    }
                    th,
                    td {
                        padding: 10px 16px 10px 0;
                    }
                }
            }
            .checkbox-container {
                width: 80%;
                flex-wrap: wrap;
                .checkbox-item {
                    min-width: 250px;
                    margin: 3px 0;
                    label {
                        cursor: pointer;
                        input {
                            cursor: pointer;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
    .swal2-input {
        font-size: 14px !important;
        border: none !important;
        color: black;
        height: auto !important;
        margin: 0em !important;
        padding: 10px 0 50px 0px !important;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }
    .swal2-inputerror {
        box-shadow: none !important;
        border-color: none !important;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .swal2-validation-message {
        font-size: 14px !important;
        background: none !important;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        justify-content: left !important;
        padding: 0.625em 0 0.625em 0 !important;
        &:before {
            margin: 0 0.625em 0 0 !important;
        }
    }
}

.small-popup {
    width: 150px !important;
    height: 150px !important;
    .swal2-header {
        padding: 14px 0px 0px 0px;
        .swal2-title {
            color: black;
            letter-spacing: 1.5px;
            font-size: 20px;
        }
    }
}

.small-error-popup {
    padding: 60px !important;
    .button {
        padding: 2px 2px 2px 2px !important;
        letter-spacing: 1.5px;
        min-width: 176px;
        height: 40px;
        font-size: 14px;
        font-weight: bold !important;
        outline: none !important;
        opacity: 1;
        cursor: pointer;
        color: black !important;
        &:hover {
            background-color: $gray-240 !important;
        }
        background-color: transparent !important;
        border-radius: 0px !important;
        border: 1.5px solid black !important;
    }
    .swal2-header {
        align-items: flex-start !important;
        .swal2-title {
            color: black !important;
            font-size: 20px;
            letter-spacing: 1.5px;
        }
    }
    .swal2-content {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        font-size: 14px !important;
        color: black !important;
        div {
            text-align: left !important;
        }
    }
}
