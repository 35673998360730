@import '../variables';
/* calendar styles */

.DateRangePickerInput {
    border-radius: 4px;
    background: transparent !important;
}

.DateRangePicker_picker {
    z-index: 10;
}

.DateRangePicker_1 {
    border-bottom: 2px solid black !important;
    margin-top: 2px;
    min-width: 200px;
    padding: 0 0px 5px 0px !important;
}

.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
    border-radius: 4px;
}

.DateInput.DateInput_1.DateInput__small.DateInput__small_2 {
    background: transparent;
}

.DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
    background: transparent;
    padding: 9px 7px 5px;
    font-weight: bold;
}

.DateInput_input__focused {
    border-bottom: 2px solid $blue-232;
}

.DayPicker__withBorder {
    border-radius: 0px !important;
    box-shadow: none !important;
}

.CalendarMonth_caption {
    color: black;
    background-color: $gray-250;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.5px;
}

.DayPicker_weekHeader_1 {
    font-weight: bold;
    font-size: 14px;
    padding: 0px !important;
    margin-left: 9px !important;
}

.DayPicker_weekHeaders {
    background: $gray-250 !important;
    padding-bottom: 10px;

    :nth-child(2) {
        left: 313px !important;
    }
    :first-child {
        left: 14px !important;
    }
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 0px !important;
}

.DateInput_fang {
    visibility: hidden;
}

.DayPicker_transitionContainer {
    background-color: $gray-250;
}
.CalendarMonth {
    background-color: $gray-250;
}

.DayPicker_weekHeader_li {
    small {
        font-weight: bold;
        font-size: 12px;
        color: black;
    }
}

.DayPickerNavigation_button__default {
    background-color: transparent;
    color: black;
    border: none !important;
}

.CalendarMonthGrid {
    background-color: $gray-250;
}

.DayPicker {
    width: auto !important;
}

.CalendarDay__default {
    border: 1px solid $gray-250;
    color: $gray-128;
    background-color: $gray-240;
}

.CalendarDay__default:hover {
    background: $gray-192;
    border: 1px double white;
    color: white;
}

.CalendarDay__selected_span {
    background: $blue-190-2;
    border: 1px solid $gray-250;
    color: white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: $blue-195-2;
    border: 1px solid $gray-250;
    color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background-color: $blue-190-2;
    border: 1px solid $gray-250;
    color: white;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: $blue-190-2;
    border: 1px solid $gray-250;
    color: white;
}

.CalendarDay__hovered_span:active {
    background: $blue-195-2;
    border: 1px solid $gray-250;
    color: white;
}

.DayPickerKeyboardShortcuts_show {
    border-right: 33px solid $blue-195-3;
    display: none;
}

.DayPickerKeyboardShortcuts_show:hover {
    border-right: 33px solid $blue-195;
    display: none;
}
