@import '../variables';
.message-container {
    margin: 0 auto;
    text-align: center;
    .message-icon {
        margin: 24px;
        height: 60px;
        &.success {
            background: url('../../assets/img/icons/success-icon.svg') center
                center no-repeat;
        }
        &.error {
            background: url('../../assets/img/icons/error-icon.svg') center
                center no-repeat;
        }
    }
    .message-text {
        font-size: 20px;
        letter-spacing: 0.75px;
        line-height: 23px;
    }
    .message-border {
        margin: 34px auto;
        border-bottom: 1px dashed $gray-184;
    }
}
