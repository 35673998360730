@import '../../scss/variables';
body.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(black, 0.7);
    z-index: 1000;
    .modal_content {
        display: grid;
        grid-template-rows: 60px auto 70px;
        margin: 40px auto;
        width: 80%;
        height: 90%;
        border-radius: 8px;
        background-color: white;
        z-index: 1010;
        .modal_header {
            border-radius: 8px 8px 0 0;
            background-color: $gray-240;
            display: grid;
            grid-template-columns: 95% 5%;
            .title {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                padding: 20px 36px;
                color: black;
            }
            .close {
                color: $blue-190-2;
                font-size: 40px;
                text-align: center;
                &:hover,
                &:focus {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .modal_body {
            background-color: white;
            width: 100%;
            height: 100%;
            padding: 36px;
            overflow-y: auto;
        }
        .modal_footer {
            display: grid;
            grid-gap: 24px;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            border-radius: 0 0 8px 8px;
            background-color: white;
            box-shadow: 0 -2px 4px 2px rgba(black, 0.05);
            padding: 14px 36px;
        }
    }
}
