@import '../../../../scss/variables';

.uploadTransactions-page {
    .margin-t-70 {
        margin-top: 70px;
    }
    .data-table-regular {
        .custom-table {
            tr {
                &:last-child {
                    td {
                        border-bottom: 1px solid $gray-192;
                        padding-top: 10px;
                        padding-bottom: 0px;
                        height: 0px;
                    }
                }
            }
        }
    }
    .custom-txt {
        font-size: 12px;
    }
    .custom-margin {
        margin-bottom: 8px;
        margin-left: 40px;
    }
    .align-center-between {
        justify-content: space-between;
        align-items: center;
    }

    .font-12px {
        font-size: 12px;
    }

    .full-width {
        width: 100% !important;
    }

    .h-separator {
        border-top: 1px solid $gray-192;
    }

    .option {
        width: 14px;
        height: 14px;
        border: 2px solid $gray-192;
    }

    .option-label {
        font-size: 15px;
    }

    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1.5px;
        position: absolute;
        right: 40px;
        z-index: 1;
        margin-top: -110px;
    }

    .upload-input {
        display: none;
    }

    .account-select {
        width: 320px;
    }

    .action-buttons-container {
        position: relative;
    }

    .action-buttons {
        position: absolute;
        bottom: 0;
    }

    .invisible-right-border {
        border-right: none;
    }
}
