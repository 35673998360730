.error-component {
    font-size: 12px;
    color: $red-13;
    display: inline-block;
    margin: 5px 0 5px 37px;
    font-weight: 500;

    i {
        margin-right: 12px;
    }
}
