.info-card {
    background-color: $gray-240;
    padding: 20px 30px;

    .title {
        color: $gray-128;
        font-size: 12px;
    }

    .item {
        .value {
            display: inline-block;
            line-height: 40px;
            font-size: 20px;
            letter-spacing: 1.5px;
        }
    }
    .item:not(:last-child) {
        border-right: 1px solid $gray-112;
        padding-right: 30px;
    }
    .item:not(:first-child) {
        padding-left: 30px;
    }
}
