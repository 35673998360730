@import '../variables';

.notification-bar {
    top: 80px;
    height: 30px;
    padding: 0px 0px 0px 50px;
    font-size: 10px;
    color: white;
    width: 50%;
    margin-left: -50px;
}
