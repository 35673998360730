@import '../variables';
.swal2-container {
    .single-transaction {
        margin-top: 200px !important;
        width: 437px;

        .title-green {
            color: $green-shade !important;
        }

        .title-red {
            color: $red-13 !important;
        }
    }

    .swal2-input {
        background: transparent !important;
    }

    .multi-transaction {
        padding: 50px 50px 60px !important;
        min-height: 200px !important;
        min-width: 800px !important;
        max-width: 1000px !important;

        .title-green {
            color: $green-shade !important;
        }

        .title-red {
            color: $red-13 !important;
        }

        .modal-table {
            width: 100%;
            tr {
                border-bottom: 2px solid $gray-208;
                &:last-child {
                    border-bottom: none;
                }
                th,
                td {
                    padding: 10px 16px 10px 0;
                }
            }
        }
    }

    .multi-transaction-request {
        margin: 350px 0 50px 0 !important;
        width: 900px;
    }

    .modal-hide {
        height: 100vh;
    }

    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input {
        color: $blue-232;
        width: 100%;
        font-size: 14px;
        border: none;
        margin: 0 !important;
        padding: 10px 0 20px !important;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__suggestions-container--open {
        margin: 0px;
        margin-top: -10px;
        box-shadow: none;
        color: $gray-128;
        display: block;
        position: absolute;
        width: 280px;
        border: 2px solid $gray-192;
        border-radius: 0px;
        background-color: white;
        outline: none;
        z-index: 2;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        max-height: 250px;
        overflow-y: auto;
    }

    .react-autosuggest__suggestion {
        padding: 10px 15px;
    }

    .react-autosuggest__suggestion:not(:last-child) {
        border-bottom: 1px solid $gray-192;
    }

    .react-autosuggest__suggestion--highlighted {
        background: none;
        color: $blue-232;
    }
}
