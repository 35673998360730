.password-change {
    .rejected {
        color: $red-4;
        position: absolute;
        margin-top: 20px;
    }
    .msg-error-parent {
        display: flex;
        margin: 38px 20px 30px 20px;
        align-items: center;
    }
}
