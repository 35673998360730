@import '../variables';

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
    grid-auto-rows: auto;
    grid-gap: 24px;
}
.card-container {
    cursor: pointer;
    display: grid;
    grid-gap: 10px;
    grid-template-rows: 20px 1fr;
    max-width: 230px;
    padding: 24px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 10px 10px 30px 0 rgba(black, 0.05),
        -10px -10px 30px 0 rgba(white, 0.06);
    word-break: break-word;
    text-transform: capitalize;

    &.active {
        border: 1px solid $blue-190-2;
    }
}
