@import '../scss/variables';
.UserProfile-page {
    color: black;
    .userProfileLogo {
        width: 100px;
    }
    .userName {
        white-space: pre;
    }
    .input-container {
        width: 250px;
    }
    .checkmark {
        div {
            width: 220px;
        }
    }
    .container-fluid {
        .border-l {
            border-left: 1px solid $gray-192;
        }
        .col-second {
            padding-left: 60px;
        }
        .changePassword-form {
            margin-top: 40px;
            .filter-item {
                margin-bottom: 40px;
                label {
                    margin-bottom: 1rem;
                }
                input {
                    border: none;
                    border-bottom: 2px solid $gray-192;
                    border-radius: 0px;
                    width: 100%;
                }
            }
            .horizontal-right {
                width: 100%;
            }
        }
        #togglerA {
            min-width: 300px;
        }
    }
    .email-catcher {
        left: -99999999px;
        position: absolute;
    }
}
