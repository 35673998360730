@font-face {
    font-family: Basier-Square;
    src: url(../assets/font/basiersquare-regular-webfont.ttf);
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: Basier-Square;
    src: url(../assets/font/basiersquare-semibold-webfont.ttf);
    font-weight: bold;
    font-display: swap;
}
