@import '../scss/variables';

.login-page {
    //background: transparent linear-gradient(307deg, $blue-195 0%, $loginpage 100%) 0% 0% no-repeat padding-box;
    background-image: url(../assets/img/loginbg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    display: grid;
    place-items: center;
    padding: 16px 0;
    color: white;

    .msg-success,
    .msg-error {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 328px;
        text-decoration: none;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .v-separator {
        border-left: 1px solid white;
    }


    .info-container {
        width: 500px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background: transparent;
        border: none;
        color: white;

        .text {
            color: white;
            margin-top: 60px;
            text-align: left;
            font-size: 20px;
            font-weight: bold;

            p {
                width: 510px;
            }
        }

        .fintech-text {
            color: white;
            margin-top: 20px;
            text-align: left;
            font-size: 16px;
            font-weight: normal;
        }

        h2 {
            font-weight: bold;
            font-size: 50px;
            max-width: 200px;
            line-height: 1;
        }

        .info-icon-section {
            display: flex;

            .info-icon-item {
                .masspay-logo {
                    width: 154px;
                    padding-right: 30px;
                }

                .nium-text-logo {
                    width: 116px;
                    padding-left: 30px;
                }

                img {
                    width: 60px;
                    height: 50px;
                }
            }
        }
    }

    .login-container {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 24px 55px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 109px;
        height: 586px;
        width: 546px;

        .login-form {
            width: 328px;
            display: flex;
            flex-direction: column;

            .login-logo {
                width: 100%;
                align-content: center;
                display: flex;

                .nium-logo {
                    width: 56px;
                    height: 56.574px;
                    margin: 40px auto;
                }

            }

            .login-subtitle {
                width: 328px;

                .header {
                    color: var(--Dark-Blue-2023, #0C0E54);
                    text-align: center;
                    font-family: Inter;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 10px;
                }

                .sub-header {
                    color: var(--Nium-Dark-Grey, #333);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 25px;
                }
            }

            form {
                width: 328px;
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                min-height: 231px;

                .login-input {
                    margin-top: 20px;
                    display: flex;
                    height: 56px;

                    input {
                        padding: 4px 0px 4px 16px;
                        background: white;
                        border: 1px solid #8F8F8C !important;
                        align-items: flex-start;
                        border-radius: 4px 4px 0px 0px !important;
                        flex: 1 0 0;
                        height: 48px;
                        color: var(--Dark-Grey-2023, #3A3A3A);
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    input::placeholder {
                        color: var(--Dark-Grey-2023, #8F8F8C);
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                    }

                    label {
                        position: relative;
                        background: #fff;
                        left: 12px;
                        top: 8px;
                        height: 16px;
                        margin-bottom: 0px;
                        width: max-content;
                        padding: 0px 4px;
                        color: var(--Additional-Gray, #8F8F8C);
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        text-transform: capitalize;
                    }
                }


                .btn-link {
                    width: 328px;
                    text-decoration: none;
                    color: var(--Link-Blue-2023, #5567F7);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    margin-top: 20px;
                    margin-bottom: 0px;
                }

                .login-btn {
                    display: flex;
                    width: 328px;
                    height: 48px;
                    padding: 8px 20px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 24px;
                    background: var(--Nium-Blue-2023, #24BAD6);
                    margin-bottom: 50px;

                    .button-lg.button-white {
                        background: var(--Nium-Blue-2023, #24BAD6) !important;
                        color: var(--White, #FFF) !important;
                        font-family: Inter !important;
                        font-size: 16px !important;
                        font-style: normal !important;
                        font-weight: 600 !important;
                        line-height: 26px !important;
                    }
                }

                .positioning-login {
                    display: flex;
                    flex: 1;
                    min-height: 40px;
                }



            }
        }
    }
}