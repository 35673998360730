@import '../variables';

.data-table-tooltip {
    padding-right: 20px;
    margin-right: 10px;
    .tooltiptext {
        visibility: hidden;
        margin-top: -50px;
        margin-left: -27px;
        position: absolute;
        background: white; // border: 1px solid $gray-208;
        padding: 20px;
        max-width: 350px;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        word-wrap: break-word;
        box-shadow: 0 10px 30px rgba(black, 0.2);
        border-radius: 10px;
        background-color: white;
        cursor: pointer;
        opacity: 0;
        transition: visibility 0.1s linear, opacity 0.1s linear;
    }
}
.data-table-tooltip.show_tooltip {
    &:hover {
        .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
    }
}
