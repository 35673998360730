@import '../variables';

.step-counter {
    .step-header-text {
        color: $gray-128;
        font-size: 12px;
    }
    .opacity {
        opacity: 0.3;
    }
    .completed {
        display: inline-block;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        height: 1.375rem;
        width: 0.75rem;
        border-bottom: 0.25rem solid $green-89;
        border-right: 0.25rem solid $green-89;
        margin-inline-start: 24px;
        margin-block-start: -15px;
    }
    .active {
        border-color: $blue-190-2;
    }
    .success {
        border-color: $green-89;
    }
    .error {
        border-color: $red-346;
    }
    .separator-container {
        padding: 0 1px;
        .separator {
            width: 100%;
            border-bottom: 1px solid $gray-156;
        }
        .step-part {
            border-bottom: 6px solid $blue-190-2;
            &.red {
                border-bottom: 6px solid $red-346 !important;
            }
        }
    }

    .space-between {
        justify-content: space-between;
    }
}
