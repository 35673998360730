@import '../scss/variables';

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-body {
    margin-top: 80px !important;
    flex: 1;

    .main {
        margin-left: 0px !important;

        .container-fluid {
            padding: 0;

            .breadcrumb {
                background-color: transparent;
                border-bottom: none;
            }

            .dashboard-view {
                min-height: 554px !important;
                background: $gray-250;
                padding: 30px 50px 30px 50px;
            }
            .dashboard-view-clientDashboard {
                min-height: 554px !important;
                background: $gray-250;
                padding: 0px 50px 120px 50px;
            }

            .page-view {
                .page-title {
                    margin-bottom: 40px;
                }
            }
        }
    }
}
