@import '../variables';

.card-container {
    &.card-button-container {
        box-shadow: none;
        display: flex;
        align-items: center;
        .card-button {
            margin: 0 auto;
            width: 55%;
            text-align: center;
            color: $blue-190-2;
            .card-button-icon {
                height: 60px;
                width: 60px;
                border-radius: 30px;
                background-color: rgba($blue-190-2, 0.15);
                margin: 0 auto;
                margin-bottom: 24px;
                span {
                    font-size: 40px;
                    font-weight: lighter;
                }
            }
            .card-button-text {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 17px;
            }
        }
    }
}
