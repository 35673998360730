@import '../scss/variables';

// Positioning
.zIndex-1 {
    z-index: 1;
}

.filter-title {
    font-size: 12px;
    //padding: 0px 10px 15px 10px;
    text-align: left;
    font-weight: normal !important;
    color: $gray-128;
    text-transform: uppercase;
}

.float-r {
    float: right;
}

.float-l {
    float: left;
}

.no-data {
    padding-top: 10px !important;
    color: $gray-128 !important;
    font-weight: normal !important;
    min-width: 100px !important;
}

.vertical-center {
    align-items: center;
    display: flex;
}

.vertical-top {
    vertical-align: top;

    tr {
        td {
            vertical-align: top;
        }
    }
}

.vertical-bottom {
    align-self: flex-end;
}

.horizontal-center {
    justify-content: center;
    display: flex;
}

.center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.modal-sm.modal-submit.batch-error {
    .swal2-actions {
        justify-content: center !important;
    }
}

.horizontal-right {
    justify-content: flex-end;
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.text-right {
    text-align: right;
}

.align-right {
    margin-left: auto;
}

.full-width {
    width: 100%;
}

.flex {
    display: flex;
}

.absolute {
    position: absolute;
}

.display-block {
    display: block;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.no-padding-left {
    padding-left: 0px !important;
}

.no-padding-right {
    padding-right: 0px !important;
}
// Buttons
.btn-primary {
    outline: 0;
    background-color: $blue-190-2;
    font-weight: bold;
}

.btn-link {
    outline: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.button-xs {
    padding: 4px 2px 2px 2px;
    min-width: 85px;
    height: 30px;
    font-size: 10px;
    font-weight: bold;
    color: black !important;
    outline: none !important;
    border: 2px solid black;
    background: transparent;
    cursor: pointer;
}

.button-2xs {
    padding: 4px 2px 2px 2px;
    min-width: 75px;
    height: 30px;
    font-size: 10px;
    font-weight: bold;
    color: black !important;
    outline: none !important;
    border: 2px solid black;
    background: transparent;
    cursor: pointer;
}

.button-sm {
    padding: 4px 2px 2px 2px;
    min-width: 142px;
    height: 50px;
    font-size: 12px;
    font-weight: bold;
    border: 2px solid black;
    color: black !important;
    outline: none !important;
    background: transparent;
    cursor: pointer;
}

.button-md {
    padding: 4px 2px 2px 2px;
    min-width: 176px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    outline: none !important;
    color: black !important;
    border: 2px solid black;
    background: transparent;
    opacity: 1;
    cursor: pointer;
}
.payin-padding {
    padding: 4px 20px 2px 20px;
}

.button-lg {
    padding: 4px 2px 2px 2px;
    min-width: 201px;
    height: 40px;
    font-size: 14px;
    color: black !important;
    border: 2px solid black;
    font-weight: bold;
    outline: none !important;
    border-radius: 0px !important;
    background: transparent;
    cursor: pointer;
}

.button-xlg {
    padding: 4px 2px 2px 2px;
    min-width: 301px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    outline: none !important;
    color: black !important;
    border: 2px solid black;
    border-radius: 0px !important;
    background: transparent;
    cursor: pointer;
}

.bg-light-grey {
    background-color: $gray-224;
    &:hover {
        background-color: $gray-164;
    }

    &:active {
        background-color: $gray-128;
    }
}

.color-black {
    color: black !important;
}

.button-light-blue {
    background-color: $blue-190-2 !important;
    color: white !important;
    border: none;
}

.button-light-blue:focus {
    background-color: $blue-190-2 !important;
}

.button-light-blue:hover {
    background-color: $blue !important;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;

    &:hover {
        background-color: $gray-192;
    }
}

.button-loading {
    font-size: 16px;
}

.bottom-alignment {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

// ==
.color-dark-blue {
    color: $blue-232;
}

.color-light-blue {
    color: $blue !important;
}

.color-dark-grey {
    color: $gray-128 !important;
}

.red {
    color: $red-346 !important;
}

.msg-error {
    color: red !important;
}

.msg-success {
    color: green !important;
}

.hide {
    display: none;
    pointer-events: none;
    cursor: default;
}

.capitalize {
    text-transform: capitalize !important;

    &:first-letter {
        text-transform: uppercase;
    }
}

.uppercase {
    text-transform: uppercase;
}

// Actions
.btn-hover-blue:hover {
    background-color: $blue-190-2;
}

.btn-hover-blue:active {
    background-color: $blue;
}

.btn-focus-blue:focus {
    background-color: $blue-190-2;
}

.font-10px {
    font-size: 10px !important;
}

// Text styles
.opacity-0 {
    opacity: 0;
}

.line-height-1 {
    line-height: 1;
}

.page-title {
    color: black;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 3px;
}

.title-h2-bold {
    color: $blue-232;
    font-weight: bold;
    font-size: 24px;
}

.title-h3-bold {
    color: black;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 1.5px;
}
.title-h3-bolder {
    color: black;
    font-weight: bolder;
    font-size: 20px;
    letter-spacing: 1.5px;
}

.title-h3-medium {
    color: $blue-232;
    font-weight: normal;
    font-size: 18px;
}

.text-bold {
    font-weight: bold !important;
}

.bold {
    font-weight: bold !important;
}

.form-group {
    vertical-align: top;
}

.dark-blue {
    color: $blue-232 !important;
}

.light-grey {
    color: $gray-192;
}

.dark-grey {
    color: $gray-128;
}

.light-blue {
    color: $blue-190-2 !important;
}

.black {
    color: black !important;
}

a:hover {
    text-decoration: none;
    color: $blue-232;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.skip_clipping {
    text-overflow: unset !important;
}

.no-width {
    width: unset !important;
}

* {
    outline: none;
}

.h-separator {
    margin: 80px 0px;
    border-top: 1px solid $gray-192;
    width: 100%;
}

.v-separator {
    border-left: 1px solid $gray-192;
}

.comma + .comma:before {
    content: ',';
    margin-right: 2.5px;
}

.split {
    border-left: 1px solid;
    height: 35px;
}

textarea {
    resize: none;
}

.no-bullet {
    padding: 0;
    list-style-type: none;
}

.newline {
    white-space: pre;
}

.table-wrapper {
    min-width: 0;
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 1;
    align-items: stretch;
}

.mHeight300 {
    min-height: 300px !important;
}

.mHeight50 {
    min-height: 50px !important;
}

.word-break-all {
    word-break: break-all;
}

.no-border {
    border: none !important;
}

.no-padding {
    padding: 0px !important;
}

.link {
    color: $blue;
    cursor: pointer;
}

.white {
    color: white;
}

.font-30px {
    font-size: 30px !important;
}

.lspacing-1 {
    letter-spacing: 1px;
}

// Background color

.background-orange {
    background: $orange;
}

.background-lightgrey {
    background: $gray-192;
}

.background-white {
    background: white;
}

.single-date-picker {
    ::placeholder {
        padding-left: 10px;
    }
    label {
        margin-bottom: 1rem;
    }
    input {
        border: none;
        background-color: $gray-240 !important;
        height: 40px;
    }
}

.font-12px {
    font-size: 12px !important;
}

.gray {
    color: $gray-164;
}
