@import '../variables';

.input-group-text {
    background: $gray-240;
    border: none;
    border-radius: 0;
}

.input-container {
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 35px;
    margin-top: 9px;
}

.input-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid $gray-128;
    div {
        margin-top: -5px;
        margin-left: 30px;
    }
}

.input-container:hover input ~ .checkmark {
    border: 1px solid black;
    div {
        color: black;
    }
}

.input-container input:checked ~ .checkmark {
    background-color: $blue-190-2;
    border: 1px solid $blue-190-2;
    div {
        color: black;
    }
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.input-container input:checked ~ .checkmark:after {
    display: block;
}

.input-container .checkmark:after {
    left: 3px;
    top: 1px;
    width: 4px;
    height: 6px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.input-item {
    .filter-title {
        font-size: 12px;
        text-align: left;
        color: $gray-128;
        text-transform: uppercase;
    }
    textarea {
        padding-top: 15px;
        padding-left: 15px;
        background-color: $gray-240;
        border: none;
        height: 100px;
        border-radius: 0px !important;
    }

    input {
        &:focus {
            border: none !important;
            background-color: $gray-240;
        }
        border: none !important;
        background-color: $gray-240;
        height: 40px;
        border-radius: 0px !important;
    }
    .file {
        padding: 5px 0 5px 0;
    }
    label {
        margin-bottom: 1rem;
    }
}

.select-places-menu {
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow: scroll;
    background: white;
    &.items {
        border: 2px solid black;
    }
    .select-places-option {
        border: 1px solid black;
        padding: 10px;
        &:not(:last-child) {
            border-bottom: 0px solid black;
        }
        &.is-focused {
            background: $gray-240;
            color: $gray-128;
        }
        &.is-selected {
            background: $blue-190-2;
            color: white;
        }
    }
    .circular-spinner.inline {
        top: 10px;
    }

    .clear-button {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        color: $gray-128;
        i {
            font-size: 18px;
        }
    }
}

.labelCustomCheckBox {
    font-size: 10px;
    justify-content: space-between;
    margin-bottom: 0rem !important;
}

.hrefDescription a {
    color: sandybrown;
}
