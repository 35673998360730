@import './../../../../scss/variables';

.sidebar-menu {
    position: fixed;
    margin-top: 80px;
    background: white;
    width: 350px;
    height: 100vh;
    z-index: 999999;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
    color: $gray-128;
    font-size: 12px;

    &.show-sidebar-false {
        &.sidebar-menu {
            margin-left: -350px !important;
        }
    }

    &.show-sidebar-true {
        &.sidebar-menu {
            margin-left: 0px !important;

            @media (max-width: $mobile-lg) {
                box-shadow: 0px 0px 0px 9999px rgba(black, 0.6);
            }
        }
    }

    .submenu-container {
        overflow-y: hidden;
        max-height: 0px;
        transition: max-height 0.3s ease-in-out;

        &.category-is-open {
            max-height: 1500px;
        }
    }

    .category-header {
        &:hover {
            color: black;

            &svg {
                color: black;
            }
        }

        svg {
            right: 30px !important;
            position: absolute;
            font-size: 9px;
            transition: 0.2s;

            &.fa-chevron-up {
                transform: rotate(180deg);
                color: black;
            }
        }

        .category {
            z-index: 1;
            width: 100%;

            &:after {
                border-top: 1px solid $gray-184 !important;
            }
        }
    }

    .menu-item {
        padding: 15px 30px 15px 50px;

        &:hover {
            color: black;
        }

        &:after {
            content: '';
            display: block;
            width: 30px;
            border-top: 1px solid $gray-184;
            margin: 14px 0px 0px 0px;
            position: absolute;
        }

        &:last-child {
            &:after {
                border-top: 0px;
            }
        }
    }

    .submenu-item {
        position: relative;
        padding: 15px 35px 15px 50px;
        margin-left: 30px;
        width: 200px;

        &:hover {
            color: black;
        }

        &:after {
            content: '';
            display: block;
            width: 30px;
            border-top: 1px solid $gray-184;
            margin: 14px 0px 0px 0px;
            position: absolute;
        }
    }

    .active-true {
        color: black;
    }

    .menu-tag {
        color: $blue;
        font-size: 7px;
        position: absolute;
        margin: 0 7px;
    }

    button {
        all: unset;
    }
}