@import '../../variables';
.rfiRow {
    display: grid;
    grid-template-columns: 3fr 5fr;
    background-color: transparent;
    padding: 25px 0px;
    cursor: default;
    border-bottom: 1px solid $gray-192;
    .gray {
        color: $gray-164;
    }
    .compliance {
        .time-details {
            display: flex;
            align-self: end;
            line-height: 14px;
            color: $gray-128;
        }
        .documentName {
            line-height: 18px;
            font-weight: bold;
            color: black;
            margin-top: 8px;
        }
        .documentDetails {
            color: $gray-128;
            line-height: 14px;
            margin-top: 8px;
        }
    }
    .client {
        display: grid;
        grid-template-columns: 30% 45% 20%;
        gap: 30px;
        align-items: flex-start;
        text-align: left;
        .file-upload-btn {
            cursor: pointer;
            .button-container {
                margin-top: 10px;
                margin-right: 100px;
                button {
                    top: 8px;
                    position: relative;
                    margin: auto;
                    padding: 8px 32px
             }
            }
        }
        .rfi-files-preview *{
            display: flex ;
            flex-direction: column;
        }
        .comments {
            margin: 0 30px;
            text-align: left;
            position: relative;
            .filter-title {
                margin: 0px;
            }
            width: 350px;
        }
        .commentsDOB {
            margin: 0 -350px;
            .filter-title {
                margin: 0px;
            }
            width: 350px;
        }
        .rfiResponseAddBtnWrapper {
            margin-top: 18px;
        }
        .addBtn {
            display: inline-block;
            text-decoration: underline;
            color: $blue-207;
        }
    }
}

.rfiBottomBtn {
    margin: 25px 0px;
    text-align: center;
    display: grid;
    place-content: center;
}
