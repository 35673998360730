$screen-lg: 1200px;
$tablet-lg: 992px;
$tablet: 768px;
$mobile-lg: 576px;
$mobile: 480px;

/* --------------------- */
/** gray colors from 50 - 100 */
/* --------------------- */
$gray-48: rgb(48, 48, 48);
$gray-80: rgb(80, 80, 80);
$gray-96: rgb(96, 96, 96);
/* --------------------- */
/** gray colors from 100 - 150 */
/* --------------------- */
$gray-112: rgb(112, 112, 112);
$gray-128: rgb(128, 128, 128);
$gray-136: rgb(136, 136, 136);

/* --------------------- */
/** gray colors from 150 - 200 */
/* --------------------- */

$gray-156: rgb(156, 156, 156);
$gray-164: rgb(164, 164, 164);
$gray-184: rgb(184, 184, 184);
$gray-192: rgb(192, 192, 192);

/* --------------------- */
/** gray colors from 200 - 250 */
/* --------------------- */

$gray-208: rgb(208, 208, 208);
$gray-224: rgb(224, 224, 224);
$gray-240: rgb(240, 240, 240);
$gray-250: rgb(250, 250, 250);

/*Black shades------------*/
$black-24: rgb(24, 24, 24);
$black-33: rgb(33, 37, 41);
$black-48: rgb(48, 48, 48);
$black-72: rgb(72, 72, 72);
$black-a20: rgba(0, 0, 0, 0.2);

// Blue colors------------------------------*/
/* --------------------- */
/** Blue colors from 150 - 200 */
/* --------------------- */
$cyan: hsla(189, 71%, 49%, 0.051);
$loginpage: hsl(190, 71%, 49%);
$blue: hsl(190, 71%, 40%);
$blue-190-2: lighten($blue, 20%);
$cyan-shade: hsl(191, 67%, 87%);

$blue-195: hsl(195, 66%, 40%);
$blue-195-2: hsl(195, 63%, 65%);
$blue-195-3: hsl(195, 100%, 44%);

$blue-207: hsl(207, 100%, 40%);
$blueHover-207: lighten($blue-207, 20%);
$blue-232: hsl(232, 48%, 24%);
$purple-shade: hsl(243, 49%, 68%);

/* Green colors---------------------------------*/

$green-89: hsl(89, 84%, 39%);
$light-green: hsl(120, 68%, 67%);
$green-shade: hsl(123, 38%, 57%);
$green: hsl(146, 58%, 54%);

// Red colors------------------------------------*/
$red-346: hsl(346, 77%, 44%);
$red-4: hsl(4, 86%, 57%);
$red-13: hsl(13, 96%, 53%);
$pink-13: hsl(13, 96%, 91%);
$orange: hsl(30, 100%, 56%);
$warning: hsl(38, 100%, 64%);

/* Generic Variable Names */
$font: Basier-Square;
$font: Basier-Square;

:export {
    primaryBackground: white;
    secondaryBackground: $blue-190-2;
    seperator: $gray-240;
    infoSecondaryText: $gray-128;
    black: black;
    altText: white;
}

.table-top-border {
    border-top: solid 1px $gray-184;
}
.table-bottom-border {
    border-bottom: solid 1px $gray-184;
}
