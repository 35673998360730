@import './variables';
@mixin vertical-center-align {
    display: flex;
    align-items: center;
}

@mixin grid-columns($col) {
    display: grid;
    grid-template-columns: $col;
}
@mixin grid-rows($rows) {
    display: grid;
    grid-template-rows: $rows;
}
@mixin side-margin {
    margin: 0 40px;
}
@mixin link() {
    color: $blue;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    text-underline-position: under;
    width: fit-content;
}

@mixin box-shadow-1 {
    box-shadow: 0px 0px 8px 2px rgba(black, 0.3);
    cursor: pointer;
}
.no-records {
    display: grid;
    justify-content: center;
    padding: 40px 20px;
    font-size: 16px;
}

.sticker {
    background-color: $blue-190-2;
    color: white;
    font-size: 12px;
    font-weight: 100;
    padding: 5px 12px;
    width: fit-content;
    height: fit-content;
}
@mixin cell-padding() {
    padding: 10px 10px 10px 0;
    &:first-child {
        padding-left: 10px;
    }
}
@mixin customOverlay() {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($gray-240, 0.7);
    top: 0;
    display: grid;
    z-index: 3;
    pointer-events: none;
    .wrapper {
        background-color: white;
        padding: 30px;
        margin: auto;
    }
}
.customOverlay {
    @include customOverlay();
}
