///////////////////////
// Margin top constants
///////////////////////
.mt-1 {
    margin-top: 5px !important;
}
.mt-2 {
    margin-top: 10px !important;
}
.mt-3 {
    margin-top: 15px !important;
}
.mt-4 {
    margin-top: 25px !important;
}
.mt-5 {
    margin-top: 50px !important;
}
.mt-8 {
    margin-top: 80px !important;
}
.mt-10 {
    margin-top: 100px !important;
}

// Custom
.margin-t-30px {
    margin-top: 30px;
}
.margin-t-40px {
    margin-top: 40px;
}
.margin-t-50px {
    // To be refactored and removed
    margin-top: 50px;
}
.margin-t-70px {
    margin-top: 70px;
}
.margin-t-80px {
    // To be refactored and removed
    margin-top: 80px;
}
.margin-t-100px {
    // To be refactored and removed
    margin-top: 100px;
}

//////////////////////////
// Margin Bottom constants
//////////////////////////
.mb-1 {
    margin-bottom: 5px !important;
}
.mb-2 {
    margin-bottom: 10px !important;
}
.mb-3 {
    margin-bottom: 15px !important;
}
.mb-4 {
    margin-bottom: 25px !important;
}
.mb-5 {
    margin-bottom: 50px !important;
}
.mb-8 {
    margin-bottom: 80px !important;
}
.mb-10 {
    margin-bottom: 100px !important;
}

// Custom

.margin-r-25px {
    margin-right: 25px;
}

.margin-b-50px {
    margin-bottom: 50px;
}

.margin-t--5px {
    margin-top: -5px;
}

.margin-b-70px {
    margin-bottom: 70px;
}
.margin-b-80px {
    margin-bottom: 80px;
}
.margin-b-100px {
    margin-bottom: 100px;
}

////////////////////////
// Margin Left constants
////////////////////////
.ml-1 {
    margin-left: 5px !important;
}
.ml-2 {
    margin-left: 10px !important;
}
.ml-3 {
    margin-left: 15px !important;
}
.ml-4 {
    margin-left: 25px !important;
}
.ml-5 {
    margin-left: 50px !important;
}
.ml-8 {
    margin-left: 80px !important;
}
.ml-10 {
    margin-left: 100px !important;
}

/////////////////////////
// Margin Right constants
/////////////////////////
.mr-0 {
    margin-right: 0px !important;
}

.mr-1 {
    margin-right: 5px !important;
}
.mr-2 {
    margin-right: 10px !important;
}
.mr-3 {
    margin-right: 15px !important;
}
.mr-4 {
    margin-right: 25px !important;
}
.mr-5 {
    margin-right: 50px !important;
}
.mr-8 {
    margin-right: 80px !important;
}
.mr-10 {
    margin-right: 100px !important;
}
.mr-11 {
    margin-right: 180px !important;
}
.mr-12 {
    margin-right: 200px !important;
}

/////////////////////////
// Padding Left constants
/////////////////////////
.pl-1 {
    padding-left: 5px !important;
}
.pl-2 {
    padding-left: 10px !important;
}
.pl-3 {
    padding-left: 15px !important;
}
.pl-4 {
    padding-left: 25px !important;
}
.pl-5 {
    padding-left: 50px !important;
}
.pl-8 {
    padding-left: 80px !important;
}
.pl-10 {
    padding-left: 100px !important;
}

// Custom
.padding-l-15px {
    padding-left: 15px;
}
.padding-l-30px {
    padding-left: 30px;
}
.padding-l-40px {
    padding-left: 40px;
}
.padding-l-45px {
    padding-left: 45px;
}

//////////////////////////
// Padding Right constants
//////////////////////////
.pr-1 {
    padding-right: 5px !important;
}
.pr-2 {
    padding-right: 10px !important;
}
.pr-3 {
    padding-right: 15px !important;
}
.pr-4 {
    padding-right: 25px !important;
}
.pr-5 {
    padding-right: 50px !important;
}
.pr-8 {
    padding-right: 80px !important;
}
.pr-10 {
    padding-right: 100px !important;
}

// Custom
.padding-r-15px {
    padding-right: 15px;
}
.padding-r-30px {
    padding-right: 30px;
}
.padding-r-45px {
    padding-right: 45px;
}

///////////////////////////
// Padding Bottom constants
///////////////////////////
.padding-b-80px {
    padding-bottom: 80px;
}

///////////////////////////////////
// Padding Left and Right constants
///////////////////////////////////
.px-4 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.px-5 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

//////////////////
// Min / Max Width
//////////////////
.min-w50 {
    min-width: 50px;
}
.min-w90 {
    min-width: 90px;
}
.min-w100 {
    min-width: 100px;
}
.min-w160 {
    min-width: 160px;
}
.min-w200 {
    min-width: 200px;
}
//
.max-w70 {
    max-width: 70px;
}
.max-w90 {
    max-width: 90px;
}
.max-w100 {
    max-width: 100px;
}
.max-w130 {
    max-width: 130px;
}
.max-w150 {
    max-width: 150px;
}
.max-w180 {
    max-width: 180px;
}
.max-w200 {
    max-width: 200px;
}
.max-w250 {
    max-width: 250px;
}
.max-w300 {
    max-width: 300px;
}
.max-w400 {
    max-width: 400px;
}
.max-w450 {
    max-width: 450px;
}
.max-w525 {
    max-width: 525px;
}
.max-w700 {
    max-width: 700px;
}
